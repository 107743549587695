<template>
  <div class="section--desc mt-5">
    <div class="subtitle--text">{{ $t('addListing.propDetail') }}</div>
    <hr />
    <div class="detail--property-wrapper mb-5 row">
      <div class="col-6 col-md-3 mb-4" v-if="type">
        <p class="mb-0 title--text">
          <b>{{ $t('addListing.propDetail') }}</b>
        </p>
        <div class="detail--text">{{ type }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="start">
        <p class="mb-0 title--text">
          <b>{{ $t('general.devstartDate') }}</b>
        </p>
        <div class="detail--text">{{ start }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="finish">
        <p class="mb-0 title--text">
          <b>{{ $t('general.devfinishDate') }}</b>
        </p>
        <div class="detail--text">{{ finish }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="sizeArea">
        <p class="mb-0 title--text">
          <b>{{ $t('addListing.sizeArea') }}</b>
        </p>
        <div class="detail--text">{{ sizeArea }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="typeCount">
        <p class="mb-0 title--text">
          <b>{{ $t('general.modelCount') }}</b>
        </p>
        <div class="detail--text">{{ typeCount }}</div>
      </div>
      <div class="col-6 col-md-3 mb-4" v-if="totalFloor && totalFloor !== 'null'">
        <p class="mb-0 title--text">
          <b>{{ $t('general.totalFloor') }}</b>
        </p>
        <div class="detail--text">{{ totalFloor }} {{ $t('general.floorCount') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    start: {
      type: String,
    },
    finish: {
      type: String,
    },
    sizeArea: {
      type: String,
    },
    totalFloor: {
      type: String,
    },
    type: {
      type: String,
    },
    typeCount: {
      type: String,
    },
  },
};
</script>
